<template>
  <div v-if="role">
    <el-form :model="role" :rules="rules" ref="ruleForm" label-width="100px">
      <el-form-item label="职务名称" prop="name">
        <el-input width="240px" v-model="role.name"></el-input>
      </el-form-item>
      <el-form-item label="职务描述" prop="desc">
        <el-input width="240px" type="textarea" :rows="2" v-model="role.desc"></el-input>
      </el-form-item>
      <template v-if="pivis">
        <div class="bg flex" v-for="(group,index) of pivis" :key="index">
          <div class="left">
            <el-checkbox @change="selectAll($event,group)" v-model="group.checkedAll">{{group.name}}</el-checkbox>
          </div>
          <div class="right flex-1">
            <el-checkbox-group @change="selectSingle($event,group)" v-model="role.privs" class="checkbox-group">
              <el-checkbox
                v-for="(pivi,_index) of group.privs"
                :label="pivi.id"
                :key="_index"
              >{{pivi.name}}</el-checkbox>
            </el-checkbox-group>
          </div>
        </div>
      </template>
      <el-form-item>
        <el-button type="primary" @click="save">确定</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import { getPrivisByCompId, saveRole, getRoleInfo } from "@/service/account";
export default {
  props: {
    roleid: Number
  },
  watch: {
    roleid() {
      if (this.roleid) this.loadRole().then();
    }
  },
  data() {
    return {
      role: {
        name: null,
        desc:null,
        privs: []
      },
      pivis: [],
      rules: {
        name: [{ required: true, message: "请输入职务名称", trigger: "blur" }]
      }
    };
  },
  async created() {
    this.pivis = await getPrivisByCompId(this.$store.state.loginUser.comp.id);
    if (this.roleid) await this.loadRole();
  },
  mounted() {

  },
  methods: {
    async loadRole() {
      let _this = this;
      this.role = {
        name: null,
        desc:null,
        privs: []
      };
      let role = await getRoleInfo(
        this.$store.state.loginUser.comp.id,
        this.roleid
      );
      Object.assign(this.role, role);
      console.log("role",this.role);
      this.tidyData();
    },
    tidyData() {
      this.pivis = Object.values(this.pivis);
      if (this.role.privs && this.role.privs.length>0) {
        // console.log(this.pivis);
        // console.log("this.role.privs",this.role.privs);
        this.pivis.forEach((e)=>{
          e.privs = Object.values(e.privs);
          let num = 0;
          e.privs.forEach((n,idx)=>{
            if (this.role.privs.indexOf(n.id)>-1) num++;
          })
          if (e.privs.length == num) {
            e.checkedAll=true;
          } else {
            e.checkedAll=false;
          }
        })
      }
    },
    selectSingle(event,arr) {
      this.tidyData();
    },
    selectAll(event,arr) {
      let list = Object.values(arr.privs);
      let allSelectedId = list.reduce((pre,cur)=>{
        if (this.role.privs.indexOf(cur.id)==-1) {
          pre.push(cur.id);
        }
        return pre;
      },[])
      if (event) { // 全选
          this.role.privs = this.role.privs.concat(allSelectedId);
      } else {  // 取消全选
        list.forEach((e)=>{
          this.role.privs.splice(this.role.privs.findIndex(item => item == e.id), 1);
        })
      }
      // console.log(allSelectedId);
      // console.log(this.role.privs);
    },
    save() {
      this.$refs["ruleForm"].validate(valid => {
        if (valid) {
          console.log(this.role);
          saveRole({
            comp_id: this.$store.state.loginUser.comp.id,
            role_id: this.role.id,
            name: this.role.name,
            desc: this.role.desc,
            privs: this.role.privs
          })
            .then(() => {
              this.$emit("onSuccess");
            })
            .catch(err => {
              console.log(err);
              this.$message.error(err.message);
            });
        }
      });
    }
  }
};
</script>
<style>
.comp-cert-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 178px;
  height: 178px;
}

.comp-cert-uploader .el-upload:hover {
  border-color: #24B1EB;
}

.comp-cert-logo-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.comp-cert-logo {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
<style scoped>
/* div >>> .el-form-item:last-child .el-form-item__content {
  margin-left: 0 !important;
} */
.bg {
  background-color: #f5f5f5;
  margin-bottom: 22px;
}
.bg .left {
  width: 100px;
  text-align: right;
  margin-right: 20px;
}
.bg .el-checkbox{
  height: 40px;
  line-height: 40px;
}
.checkbox-group {
  display: flex;
  flex-direction: column;
}
.el-input,
.el-textarea {
  width: 240px;
}
</style>